
/* 
**************
Section: Title
************** 
*/
.product-card {
    background-color: white;
    margin: 1em 1.25em 0 1.25em;
    padding: 1em 0;
    border-radius: 1.2em;
    box-shadow: 0 0 5px rgb(150,150,150, 0.2);
}
#product-section-title {
    display: flex;
    flex-direction: row;

    justify-content: flex-start;
    align-items: center;

    margin: 0 ;
    padding: 0 1.25em;
}

#product-section-title picture {
    width: 20%;
    height: 100%;
    
    border-radius: 12.5%;
}

#product-section-title picture img {
    width: 100%;
    border-radius: 12.5%;
}

#product-section-title-info-container {
    display: flex;
    flex-direction: column;
    margin-left: 1em;
    width: 70%;
    height: 6em;
    justify-content: center;
}

#product-section-title-info-container h1 {
    margin: 0;
    font-size: 1.25em;
}

#product-section-title-info-container p.product-title-name-details {
    display: inline-block;
    width: 100%;

    color:#000000;
    font-size: .9em;
}

#product-section-title-info-container p {
    margin: 0;
    color: #a0a0a0;
    font-weight: 500;
}

/* 
**********************
Section: Product Score
********************** 
*/

#product-section-environmental-score {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em 2em;
}

#product-section-environmental-score h2 {
    margin: 0 0 .2em 0;

    font-size: 1em;
}

#product-section-environmental-score h2+p {
 margin: 0 0 1.25em 0;
}

#product-section-environmental-score p {
    margin: 0;

    font-size: .8em;
    color: #a0a0a0;
    font-weight: 600;
}

#product-section-environmental-score .label-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
#product-section-environmental-score .label-section p{
    margin: 0
}

.score-section {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.product-section-environmental-score-pastil {
    width: 1.125em;
    height: 1.125em;
    border-radius: 100%;
    margin-right: .25em;
}

p#product-section-environmental-score-scoring {
    font-size: 0.9em;
    color: rgb(11, 11, 11);
    font-weight: 700;
}

/* 
*******************************
 Section: Environmental Impacts 
*******************************
*/

#product-section-environmental-impacts {
    margin: 1em 1.25em 0 1.25em;
}

#product-section-environmental-impacts h2 {
    margin: 2em 0 0 0;
    font-size: 1em;
}

#product-section-environmental-impacts-cards-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.product-section-environmental-impacts-card {
    display: flex;
    flex-direction: column;
    
    width: 45%;
    background-color: rgb(255, 255, 255);
    border-radius: 1.2em;
    
    margin: 1.5em .5em 0 0;
}

.product-section-environmental-impacts-card-info,
.product-section-environmental-impacts-secondary-card-info {
    padding: .75em .5em .5em .75em;

    border-top-right-radius: 1.2em;
    border-top-left-radius: 1.2em;
    background-color: #e6e6e6;
}

.product-section-environmental-impacts-secondary-card-info {
    display: flex;
    align-items: center;
    height: 2.5em;

    font-weight: 600;
}

.product-impacts-secondary-card {
    padding: .75em .5em .5em .75em;
    border-radius: 1em;
    background-color: #e6e6e6;
}

.product-impacts-secondary-card-info {
    padding: .25em .5em .5em .75em;
}

.product-section-environmental-impacts-card-info p,
.product-impacts-secondary-card-info p {
    margin: 0;
    font-weight: 600;
}

p.product-impacts-card-info-name {
    font-size: .7em;
}

p.product-impacts-card-info-scoring,
p.product-impacts-secondary-card-info {
    font-size: 1.2em;
    color: rgb(0, 0, 0);
    font-weight: 700;
}

p.product-impacts-card-info-unit,
p.product-impacts-secondary-card-info {
    font-size: .66em;
}

.product-section-environmental-impacts-card-comparison {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 0.25em 0.5em 0.5em 0.5em;
}

.product-section-environmental-impacts-card-comparison span {
    display: inline-block;
    font-size: 1.5em;
    height: 1.25em;
    margin-right: .25em;
}

p.product-impacts-card-comparison-scoring {
    display: inline-block;
    height: 1em;
    margin: 0;

    font-size: .8em;
    font-weight: 800;
}

.product-section-environmental-impacts-display-more p {
    font-size: .75em;
    color: #1061db;
    text-decoration: underline;
    text-underline-offset: .25em;
    font-weight: 700;
}

/*
************************
 Section: Category Score
************************
*/

#product-section-category-score {
    margin: 1.75em 1.25em 0 1.25em;
}

#product-section-category-score h2 {
    font-size: 1em;
    margin: 3em 0 1em 0;
}

    /* Category Gauge*/
    #product-section-category-score-gauge-container {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        margin: 0 auto;

        padding: 0 0 3.5em 0;
        border-bottom: 1px solid #e6e6e6;
    }

    #category-score-gauge {
        margin: 1.25em auto 0 auto;

        width: 100%;
        height: 1.5px;
        background: linear-gradient(to right, green, orange, red);
        position: relative;
        border-radius: 1em;
    }
    
    .category-product-score-indicator {
        transition: height 0.3s ease-in-out, width 0.3s ease-in-out, top 0.3s ease-in-out, left 0.3s ease-in-out;
        position: absolute;
        top: -8px;
        content: "";

        width: 10px;
        height: 10px;
        border-radius: 100%;
        border: 4px solid #f9f1e8;
    }

    .category-product-score-main-arrow-indicator {
        position: absolute;
        top: -16px;
        left: 40%;
        content: "";
        background-color: #e6e6e6;

        width: 20%;
        height: 15px;
    }

    .category-product-score-comparison-arrow-indicator {
        position: absolute;
        top: 12px;
        left: 40%;
        content: "";
        background-color: #e6e6e6;

        width: 20%;
        height: 15px;
    }

    .hidden-comparison-arrow {
        visibility: hidden;
    }

    .show-comparison-arrow {
        visibility: visible;
    }

    /* Product Cards */
    #carousel-container {
        display: flex;
        flex: none;
        overflow-x: auto;
        overflow-y: hidden;
        scroll-snap-type: x mandatory;
        width: 100vw;
        margin-left: -1.25rem;
        flex-flow: row nowrap;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    #carousel-container::-webkit-scrollbar {
        display: none;
    }

    .left-arrow-group, .right-arrow-group {
        position: absolute;
        top: 2.5rem;
        padding: 0 0.3rem;
        width: 4rem;
        font-size: .65em;
        color: #1061db;
        text-decoration: underline;
        text-underline-offset: .25em;
        font-weight: 700;
        text-align: center;
    }
   
    .left-arrow-group.disabled, .right-arrow-group.disabled {
        color: rgb(100,100,100);
        text-decoration: none;
    }

    .left-arrow-group > svg, .right-arrow-group  > svg {
        font-size: 1rem
    }

    .left-arrow-group {
        left: -8px;
    }
    .right-arrow-group {
        right: -8px;
    }

    .product-card-container {
        scroll-snap-align: center;
        width: 100%;
    }
    #product-section-category-score-gauge-container .category-product-card {
        display: flex;
        flex-direction: row;
        position: relative;
        width: 200px;
        margin: 10px calc(50vw - 100px);
        height: 6.5em;
        background-color: rgb(252, 252, 252);
        border-radius: 1em;
        z-index: 1;
    }

    #main-product-card {
        top: 0;
    }

    #comparison-product-card {
        top: 21px;
    }

    div#comparison-product-card.hidden-card  {
        display: none;
    }

    div#comparison-product-card.show-card {
        display: flex;
    }

    #product-section-category-score-gauge-container picture.category-product-card-picture {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 30%;
        margin: 0;
    }

    #product-section-category-score-gauge-container picture.category-product-card-picture img {
        width: 100%;
        margin: 0;
        border-radius: .5em;
    }

    #product-section-category-score .category-product-info {
        margin: auto 0.2em;
        width: 70%;
    }

    #product-section-category-score .category-product-info > h3 {
        margin: 0 0.1rem 0 0;
        
        font-size: 0.8em;
        text-align: left;
    }

    #product-section-category-score .category-product-info > p {
        margin: 0 0 .75em 0;

        font-size: .55em;
        text-align: left;
        color: #a0a0a0;
        font-weight: 500;
    }

    #product-section-category-score .category-product-info .score-section p#product-section-environmental-score-scoring{
        margin: 0;
        font-size: .8em;
        color: black;
        font-weight: 600;
    }

    #product-section-category-score .category-product-info .score-section p{
        margin: 0;
        font-size: .7em;
        color: #a0a0a0;
        font-weight: 600;
    }

    #product-section-category-score p.category-product-unity {
        font-size: .6em;
    }

    #product-section-category-score p.category-product-scoring {
        margin: 0;

        font-size: .9em;
        text-align: left;
        color: rgb(11, 11, 11);
        font-weight: 700;
    }


/*
************************
 Section: Footer
************************
*/

#product-section-footer picture {
    display: block;
    width: 3em;
    margin: 1.5em auto 1em;
}

#product-section-footer img {
    width: 3em;
}

#product-section-footer p {
    font-size: 1em;
    text-align: center;
    color: rgb(2, 2, 2);
    font-weight: bold;
}


/* 
*************
Media queries
*************
*/

@media (min-width: 700px) {

    /* Product Score */
    #product-section-environmental-score {
        width: 25%;
    }

    /* Impacts */
    .product-section-environmental-impacts-card {
        width: 30%;
    }

    /* Category Score */
    .category-product-score-indicator {
        top: -9px;
        width: 10px;
        height: 10px;
    }

    .category-product-score-comparison-arrow-indicator {
        top: 15px;
        height: 12px;
    }

    #product-section-category-score-gauge-container .category-product-card {
        height: 8.5em;
    }

    #product-section-category-score-gauge-container picture.category-product-card-picture {
        width: 5em;
    }

    #product-section-category-score .category-product-info > h3 {
        font-size: 1.17em;
    }

    #product-section-category-score .category-product-info > p {
        font-size: .8em;
    }

    #product-section-category-score p.category-product-unity {
        font-size: .9em;
    }

    #product-section-category-score p.category-product-scoring {
        font-size: 1.4em;
    }
}

@media (min-width: 900px) {

    /* Product Score */
    #product-section-environmental-score {
        width: 17.5%;
    }

    /* Impacts */
    .product-section-environmental-impacts-card {
        width: 25%;
    }

    /* Category Score */
    .category-product-score-indicator {
        top: -11px;
        width: 10px;
        height: 10px;
    }

    .category-product-score-comparison-arrow-indicator {
        top: 17px;
        height: 14px;
    }

}

@media (min-width: 1000px) {

    /* Impacts */
    .product-section-environmental-impacts-card {
        width: 20%;
    }

    .category-product-score-indicator {
        top: -8px;
        width: 10px;
        height: 10px;
    }

    .category-product-score-main-arrow-indicator {
        top: -14px;
        height: 13px;
    }
}