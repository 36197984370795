#header picture {
    display: block;
    width: 100%;
    /* padding: .75em 1em .75em 3.5em; */
    padding: .75em 1em .75em 1.5em;
    border-bottom: 1px solid #e4dfdf;
    background-color: rgb(252, 252, 252);
}

#header picture img {
    width: 5em;
}

#header nav {
    width: 100%;
    padding: 0.7em 0 0 0;
    /* padding: .125em 1em .125em 3em; */
    /* border-bottom: 2px solid #e4dfdf; */

    /* background-color: #ff6658; */
}

#header nav a,
#header nav a:visited {
    color: black;
    font-size: 0.9em;
    font-weight: 500;
    text-decoration: none;
}

#header nav .nav-links > span:last-child > a {
    color: #ff6658;
    font-weight: bold;
    text-decoration: none;
}

.nav-links {
    width: 85%;
    margin: 0 auto;
    padding: .125em 0;
}