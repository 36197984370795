#title-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 3em 0;
    background-image: url("/public/title-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.title-container {
    width: 85%;
    text-align: center;

}

.title-container h1 {
    font-family: 'Tropiline';
    font-size: 2em;
    font-weight: 500;
    color: black;

    margin: 0 0 .5em;
    
}

span.underlined {
    background-image: linear-gradient(#f7cab9, #f7cab9);
    background-size: 100% 12px;
    background-repeat: no-repeat;
    background-position: left 92%;
}

.title-container h1+p {
    font-family: 'Tropiline';
    font-size: 1.05em;
    font-weight: 500;
    color: #ff6658;
    margin: 0;
}

.title-underline {
    margin: .75em auto .5em;

    width: 60%;
    height: 1.5px;
    background: linear-gradient(to right, #FF0000, #FFC000, #7EFF00);
    position: relative;
    border-radius: 1em;
}

.title-container p {
    font-size: 1.05em;
    font-weight: 500;

    margin: 0;
}