#service-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin: 1em 0;

    width: 100%;
}

#service-section .service-categories-container {
    width: 100%;
    padding: 0 0;
    box-shadow: none;
    background-color: transparent;
}


.service-categories-container > div {
    background-color: transparent;
}

#service-section .service-categories-container::before {
    opacity: 1;
}

/* 
*************************
    Categories Titles
*************************
*/

.service-categories-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.service-categories-title-icon {
    width: 4%;
    margin-right: .5em;
    font-weight: 500;
    font-size: 1.2em;
}

.service-categories-title-container h2 {
    margin: 0;
    height: 1rem;
    line-height: 1rem;
    font-size: 1rem;
    font-weight: 600;
}

.service-categories-title-picture {
    width: 3em;
    margin-right: 1rem;
}

.service-categories-title-picture img {
    width: 100%;
}

/* 
**********************
    Products Lists 
**********************
*/

.hide-li,
.hide-ul {
    display: none;
}

.show-li {
    display: list-item;
}

.show-ul {
    display: block;
}

.service-categories-ul {
    margin: 1em 0 0;
    list-style-type: none;
    padding-left: 0;
}

.service-categories-ul p {
    margin: .125em 0 0;

    font-size: .9em;
    font-weight: 500;
}

.service-categories-ul a,
.service-categories-ul a:visited {
    display: inline-block;
    margin: 0.75em 0;
    padding: .3em .7em;
    
    border: 1px solid #599BFF;
    border-radius: .3em;

    font-size: .8em;
    font-weight: 500;

    text-decoration: none;
    color: #1669B1;
}

.service-categories-ul a:active {
    background-color: #599BFF;
    color: #ffffff;
}

/* 
**********************
    Products Cards 
**********************
*/

.service-product-card {
    display: flex;
    flex-direction: column;
    background-color: rgb(252, 252, 252);
    border-radius: .5em;
    padding: .75em 1em;
    margin-bottom: 1em;
}

.service-product-info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.service-product-info-container p {
    display: inline-block;
    width: 70%;
    font-weight: 500;
}

p.service-product-info-name-details {
    font-size: .8em;
    font-weight: 400;
}

.service-product-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center
}

.service-product-select-quantity {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 25%;

    border: 1px solid #DEDBDB;
    border-radius: .2em;
}

.service-product-select-quantity button {
    width: 33.3%;
    height: 1.5em;

    border: none;
    background-color: #F5F5F5;

    font-size: 1.2em;
    font-weight: 500;
}

.service-product-select-quantity p {
    display: inline-block;
    width: 33.3%;

    text-align: center;
    margin: 0;

    font-weight: 600;
}