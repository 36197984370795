/* 
****************************
    Service Days section
****************************
*/

#home-service-days-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin: 0 0 1.5em 0;

    width: 100%;
}

.home-service-days-link-container {
    width: 85%;
    margin: 0 auto 1em;
    padding: 0 0 1em;

    border-bottom: 1px solid #e4dfdf;
}

.home-service-days-link-container a,
.home-service-days-link-container a:visited {
    text-decoration: none;
    color: #000000;
    font-weight: 500;
}

.section-hint {
    margin-left: 7%;
    margin-bottom: 2rem;
    font-weight: 700;
}