
#product-section-footer picture {
    display: block;
    width: 3em;
    margin: 1.5em auto 1em;
}

#product-section-footer img {
    width: 3em;
}

.product-section-footer-slogan-container {
    width: 95%;
    margin: 0 auto;
}

#product-section-footer p {
    font-size: 1em;
    text-align: center;
    color: rgb(2, 2, 2);
    font-weight: bold;
}

.cookies-link-container {
    text-align: center;
    margin-bottom: 1em;
}

.cookies-link-container a {
    color: #000000;
    font-size: .8em;
}