#calculator-section {
    position: fixed;
    bottom: 0%;

    width: 100%;
}

#calculator-section.show-calculator {
    height: 32em;
}

#calculator-section.hide-calculator {
    height: 5.75em;
}

#calculator-section .hide-show-span-container {
    display: block;
    width: 45%;
    margin: 0 auto;
    padding: .125em 0 .125em;

    text-align: center;

    background: linear-gradient(to right, #e4dfdf, #f5f5f5, #e4dfdf);

    border-top: 1px solid #e4dfdf;
    border-left: 1px solid #e4dfdf;
    border-right: 1px solid #e4dfdf;
    border-top-left-radius: .5em;
    border-top-right-radius: .5em;
}

/* 
****************************
    Calculator container
****************************
*/

#calculator-container {
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px rgb(150, 150, 150, 0.6);
    border-radius: 15px 15px 0 0;
    width: 100%;
    margin: 0 auto;
    padding: 1.2em 0 .66em;

    background: linear-gradient(to right, #e4dfdf, #f5f5f5, #e4dfdf);
    border-top: 1px solid #e4dfdf;
}

#pull-anchor {
    width: 50px;
    height: 5px;
    background-color: rgb(200, 200, 200);
    border-radius: 3px;
    position: absolute;
    top: 8px;
    left: calc(50% - 25px);
}

#calculator-container.calculator-container-up {
    height: 95%;
}

#calculator-container.calculator-container-down {
    height: auto;
    padding: 1.2em 0 2em; 
}

/* 
*********************
    Total info
*********************
*/

#calculator-total-info-container {
    width: 90%;
    margin: 0 auto;
    background: linear-gradient(to right, #297AF2, #4D94FF, #297AF2);
    color: #ffffff;
    border: none;
    font-size: 1em;
    font-weight: 500;
    text-align: center;
}

@keyframes bounce{
    from {margin-top: 0em;width: 90%; font-size: 1rem}
    20% {margin-top: -0.05em;width: 90.5%; font-size: 1.01rem}
    30% {margin-top: -0.15em;width: 91.5%; font-size: 1.03rem}
    50%   {margin-top: -0.175em;width: 91.75%; font-size: 1.035rem}
    60% {margin-top: -0.15em;width: 91.5%; font-size: 1.03rem}
    80% {margin-top: -0.02em;width: 90.2%; font-size: 1.005rem}
    to {margin-top: -0em;width: 90%; font-size: 1rem}
}

.bounce {
    animation: bounce 0.25s ease infinite;
}

.total-info-up {
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
}

.total-info-down {
    border-radius: .5em;
}

#calculator-total-info-container p {
    display: block;
    margin: .5em 0 .75em;
}

/* 
**********************************
    Total Impacts Container
**********************************
*/

#calculator-total-impacts-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 1em;

    background-color: #ffffff;
    border: 1px solid #e4dfdf;
    border-bottom: 1px solid #ffffff;
}

#calculator-total-impacts-container.hide-total-impacts{
    display: none;
}

.calculator-total-impact {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 40%;
}

.impact-0 {
    color: #d68d03;
}

.impact-1 {
    color: #0C4EB2;
}

.total-impact-name {
    font-size: .75em;
    font-weight: 700;
    margin: .33em 0 .33em;
}

.total-impact-unity {
    font-size: .75em;
    font-weight: 700;
    margin: .33em 0 .33em;
}

/* 
*******************************
    Switch Buttons Containers
*******************************
*/

.calculator-switch-buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 90%;
    background-color: white;
    margin: 0 auto;
    padding-bottom: 1em;
}

.calculator-switch-buttons-container.hide-switch-buttons {
    display: none;
}

/* 
**********************************
    Categories Chart Containers
**********************************
*/

.VictoryContainer {
    touch-action: auto !important;
    pointer-events: auto !important;
}

.chart-alert {
    text-align: center;
}

.calculator-chart-container svg {
    height: 17em !important;
}

.calculator-product-chart-info-container {
    width: 95%;
    margin: .5em auto 0;
}

.calculator-product-chart-name-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.calculator-product-chart-color-legend {
    width: 1em;
    height: 1em;
    margin-right: .5em;
}

.calculator-product-chart-name-container p {
    margin: 0 0 .33em 0;
    font-size: .85em;
    font-weight: 500;
}

/* 
**********************************
    Categorie Lists Containers
**********************************
*/

#calculator-chart-and-list-container {
    width: 90%;
    margin: 0 auto .5em;

    overflow: scroll;
    overscroll-behavior: contain;

    background-color: white;
    height: 87.5%;

    margin: 0 auto;
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
    border: 1px solid #e4dfdf;
    border-top: none;
}

#calculator-chart-and-list-container.show-calculator-list {
    display: block;

}

#calculator-chart-and-list-container.hide-calculator-list {
    display: none;
}

/* 
****************************
    Categorie Containers
****************************
*/

.calculator-categories-container {
    width: 90%;
    margin: 0 auto;
    padding-bottom: .5em;
    border-bottom: 1px solid #e4dfdf;
}

.calculator-categories-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-top: .5em;
}

.calculator-categories-title-icon {
    width: 4%;
    margin-right: 0.5em;
    font-weight: 500;
    font-size: 1.2em;
}

.calculator-categories-title-container h3 {
    margin: 0;
    font-size: 1em;
    font-weight: 500;
}

.calculator-categories-title-picture {
    display: block;
    width: 2em;
}

.calculator-categories-title-picture img{
    width: 100%;
}

.calculator-categories-ul {
    list-style-type: none;
    padding-left: 0;
    margin-top: 0;
}

.show-list {
    display: block;
}

.hide-list {
    display: none;
}

/* 
****************************
    Product Card
****************************
*/

.calculator-product-card {
    display: flex;
    flex-direction: column;

    border: 1px solid #DEDBDB;
    border-radius: .5em;
    padding: .5em 1em;
    margin-bottom: 1em;
}

.calculator-product-info-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.calculator-product-info-container p{
    display: inline-block;
    font-size: .9em;
}

.calculator-info-product-name-container {
    display: flex;
    flex-direction: column;
    width: 60%;
}

P.calculator-info-product-name {
    width: 100%;
    text-align: left;
    margin: 0;
}

P.calculator-info-product-name-details {
    font-size: .7em;
    font-weight: 400;
    margin: 0;
}

P.info-product-quantity {
    width: 10%;
    text-align: right;
}

P.info-product-score {
    width: 30%;
    text-align: right;
}

/* 
****************************
    Impacts
****************************
*/

.calculator-product-impacts-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: .75em;
}

.hide-product-impacts {
    display: none;
}

.calculator-product-impact {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
}

.pastil {
    width: 1.5em;
    height: 1.5em;
    border-radius: 100%;
}

.product-impact-0 {
    background-color: #ffa500;
}

.product-impact-1 {
    background-color: #0C4EB2;
}

.product-impact-score {
    color: #000000;
    font-size: .7em;
    font-weight: 700;

    margin: .5em 0 .5em;
}

.view-more-impact-on-click {
    margin: .5em 0 .5em;
    font-size: .75em;
    color: #1061db;
    font-weight: 700;
}

/* 
****************************
    Add product Containers
****************************
*/

.calculator-add-product-container {
    width: 90%;
    margin: 1em auto .75em;
}

.calculator-add-product-container p {
    margin: 0;
    font-size: 1em;
    font-weight: 500;
}